<template>
  <div class="hiddendiv" style="display: none;">
    <p>To be done</p>
  </div>
</template>

<script>
export default {
  name: 'FunMode',
}
</script>

<style>

</style>
